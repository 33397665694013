<template>
  <div>
    <v-layout wrap justify-center>
      <v-snackbar v-model="showSnackBar" color="black">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: white">
              {{ msg }}
            </span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <vue-element-loading
        :active="appLoading"
        :is-full-screen="true"
        background-color="#FFFFFF"
        color="#283e51"
        spinner="spinner"
      />
      <v-flex>
        <v-layout wrap justify-center>
          <v-flex xs11 text-center pt-7 pb-2>
            <span style="font-size: 23px; font-weight: bold"
              >Application form for online reservation of Nature camp - With
              Fee</span
            >
          </v-flex>
          <v-flex pa-4 xs12 text-center>
            <v-card>
              <v-layout wrap justify-center>
                <v-flex xs12 text-center>
                  <v-card>
                    <v-layout wrap justify-center>
                      <v-flex
                        pl-4
                        pt-2
                        pb-2
                        xs12
                        text-left
                        style="background-color: black"
                      >
                        <span style="font-size: 20px; color: white"
                          >Select Date and Duration</span
                        >
                      </v-flex>
                      <v-flex xs12>
                        <v-layout wrap>
                          <v-flex pl-3 xs11 lg4 text-left align-self-center>
                            <v-autocomplete
                              disabled
                              outlined
                              dense
                              hide-details="auto"
                              color="black"
                              :items="districts"
                              v-model="dist"
                              item-text="name"
                              item-value="name"
                              item-color="#FF1313"
                              clearable
                              class="custom-autocomplete mainfont"
                            >
                              <template v-slot:label>
                                <span
                                  class="custom-label-text"
                                  style="color: black; font-size: 14px"
                                  >District
                                  <span style="color: red; font-size: 20px"
                                    >*</span
                                  >
                                </span>
                              </template>
                              <template v-slot:append>
                                <v-icon color="black">mdi-chevron-down</v-icon>
                              </template>
                            </v-autocomplete>
                          </v-flex>

                          <!-- <v-flex xs11 lg4 pl-3 align-self-center pt-6>
                            <v-menu
                              ref="menu1"
                              v-model="menu1"
                              :close-on-content-click="false"
                              max-width="290"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  clearable
                                  dense
                                  outlined
                                  readonly
                                  v-model="startDate"
                                  v-bind="attrs"
                                  background-color="white"
                                  v-on="on"
                                  @click:clear="fromDate = null"
                                  style="color: black"
                                >
                                  <template v-slot:append>
                                    <v-icon color="black"
                                      >mdi-chevron-down</v-icon
                                    >
                                  </template>
                                  <template v-slot:label>
                                    <span
                                      class="custom-label-text"
                                      style="color: black; font-size: 14px"
                                      >From Date</span
                                    >
                                  </template>
                                </v-text-field>
                              </template>
                              <v-date-picker
                                v-model="startDate"
                                color="#13736f"
                                @change="menu1 = false"
                                :min="minDate"
                              ></v-date-picker>
                            </v-menu>
                          </v-flex> -->
                          <v-flex xs11 lg4 pl-3 align-self-center>
                            <v-menu
                              ref="menu1"
                              v-model="menu1"
                              :close-on-content-click="false"
                              max-width="290"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  clearable
                                  dense
                                  outlined
                                  readonly
                                  hide-details="auto"
                                  v-model="formattedStartDate"
                                  v-bind="attrs"
                                  background-color="white"
                                  v-on="on"
                                  @click:clear="fromDate = null"
                                  style="color: black"
                                >
                                  <template v-slot:append>
                                    <v-icon color="black"
                                      >mdi-chevron-down</v-icon
                                    >
                                  </template>
                                  <template v-slot:label>
                                    <span
                                      class="custom-label-text"
                                      style="color: black; font-size: 14px"
                                    >
                                      From Date
                                    </span>
                                  </template>
                                </v-text-field>
                              </template>
                              <v-date-picker
                                v-model="startDate"
                                color="#13736f"
                                @change="menu1 = false"
                                :min="minDate"
                                :allowed-dates="filterAllowedDates"
                              ></v-date-picker>
                            </v-menu>
                          </v-flex>
                          <!-- 
                          <v-flex xs11 lg4 pl-3 align-self-center>
                            <v-menu
                              ref="menu1"
                              v-model="menu1"
                              :close-on-content-click="false"
                              max-width="290"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  clearable
                                  dense
                                  outlined
                                  readonly
                                  hide-details="auto"
                                  v-model="formattedStartDate"
                                  v-bind="attrs"
                                  background-color="white"
                                  v-on="on"
                                  @click:clear="fromDate = null"
                                  style="color: black"
                                >
                                  <template v-slot:append>
                                    <v-icon color="black"
                                      >mdi-chevron-down</v-icon
                                    >
                                  </template>
                                  <template v-slot:label>
                                    <span
                                      class="custom-label-text"
                                      style="color: black; font-size: 14px"
                                      >From Date</span
                                    >
                                  </template>
                                </v-text-field>
                              </template>
                              <v-date-picker
                                v-model="startDate"
                                color="#13736f"
                                @change="menu1 = false"
                                :min="minDate"
                              ></v-date-picker>
                            </v-menu>
                          </v-flex> -->

                          <v-flex xs11 lg4>
                            <v-layout wrap justify-center>
                              <v-flex xs6 align-self-center>
                                <span
                                  >Duration of Camp
                                  <span style="color: red; font-size: 20px"
                                    >*</span
                                  >
                                </span>
                              </v-flex>
                              <v-flex xs11 lg4>
                                <v-radio-group v-model="campDuration">
                                  <v-radio label="1 day" value="1"></v-radio>
                                  <v-radio label="2 days" value="2"></v-radio>
                                  <v-radio label="3 days" value="3"></v-radio>
                                </v-radio-group>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <!-- <v-flex xs6 pb-6>
                        Selected camp at on

                        &nbsp; <span style="color:red"> {{ startDate }}</span>&nbsp;
                        can accommodate people as follows
                      </v-flex> -->
                      <!-- <v-flex xs8 pt-3 pb-9>
                        <v-card elevation="0" style="border: 1px solid black; border-radius: 0px;">
                          <v-layout wrap justify-center>
                            <v-flex xs12>
                              <v-layout wrap justify-center>
                                <v-flex xs3 pt-2 text-center>
                                  <span style="font-weight:bold">Staff/Group Leader</span>
                                </v-flex>
                                <v-flex pt-2 xs3 text-center>
                                  <span style="font-weight:bold">Male Members</span>
                                </v-flex>
                                <v-flex pt-2 xs3 text-center>
                                  <span style="font-weight:bold">Female Members</span>
                                </v-flex>
                                <v-flex pt-2 xs3 text-center pr-3>
                                  <span style="font-weight:bold">Transgender Members</span>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12 pt-2 pb-2>
                              <v-layout wrap>
                                <v-flex xs12>
                                  <v-divider></v-divider>

                                </v-flex>
                              </v-layout>

                            </v-flex>
                            <v-flex xs12>
                              <v-layout wrap justify-center>
                                <v-flex pb-2 xs3 text-center>
                                  <span>{{ list.totalMembers }}</span>
                                </v-flex>
                                <v-flex pb-2 xs3 text-center v-if="list">
                                  <span v-if="list.membersLimit">{{ list.membersLimit.male }}</span>
                                </v-flex>
                                <v-flex pb-2 xs3 text-center v-if="list">
                                  <span v-if="list.membersLimit">{{ list.membersLimit.female }}</span>
                                </v-flex>
                                <v-flex pb-2 xs3 text-center v-if="list">
                                  <span v-if="list.membersLimit">{{ list.membersLimit.transgender }}</span>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-card>
                      </v-flex> -->
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs12 text-center v-if="message === 0">
            <span style="font-size: 20px; color: rgb(255, 0, 0)"
              >No Slots Available !</span
            >
          </v-flex>
          <v-flex xs12 v-else>
            <v-layout wrap justify-center>
              <v-flex pa-4 xs12 text-center>
                <v-card>
                  <v-layout wrap>
                    <v-flex xs12 text-center>
                      <v-card>
                        <v-layout wrap justify-center>
                          <v-flex
                            pl-4
                            pt-2
                            pb-2
                            xs12
                            text-left
                            style="background-color: black"
                          >
                            <span style="font-size: 20px; color: white">
                              Applicant Details
                            </span>
                          </v-flex>
                          <v-flex xs12>
                            <v-layout wrap>
                              <!-- <v-flex pl-3 xs11 lg3 pt-6 pb-6 text-left align-self-center>
                                <v-text-field outlined dense clearable hide-details="auto" type="number"
                                  class="inputPrice" v-model="maleCount" @input="countMale">
                                  <template v-slot:label>
                                    <span class="custom-label-text" style="color: black; font-size: 14px">
                                      Number of Males<span style="color: red; font-size: 20px">*</span>
                                    </span>
                                  </template>
                                </v-text-field>
                              </v-flex> -->
                              <!-- <v-flex pl-3 xs11 lg3 pt-6 pb-6 text-left align-self-center>
                                <v-text-field outlined dense clearable hide-details="auto" type="number"
                                  class="inputPrice" v-model="femaleCount" @input="countMale">
                                  <template v-slot:label>
                                    <span class="custom-label-text" style="color: black; font-size: 14px">
                                      Number of Females<span style="color: red; font-size: 20px">*</span>
                                    </span>
                                  </template>
                                </v-text-field>
                              </v-flex> -->
                              <!-- <v-flex pl-3 xs11 lg3 pt-6 pb-6 text-left align-self-center>
                                <v-text-field outlined dense clearable hide-details="auto" type="number"
                                  class="inputPrice" v-model="transCount" @input="countMale">
                                  <template v-slot:label>
                                    <span class="custom-label-text" style="color: black; font-size: 14px">
                                      Number of Transgenders<span style="color: red; font-size: 20px">*</span>
                                    </span>
                                  </template>
                                </v-text-field>
                              </v-flex> -->
                              <!-- <v-flex pl-3 xs11 lg3 pt-6 pb-6 text-left align-self-center>
                                <v-text-field dense color="#283e51" outlined background-color="white"
                                  :value="totalParticipants" v-model="totalParticipants" disabled hide-details="auto">
                                  <template v-slot:label>
                                    <span class="custom-label-text" style="color: black; font-size: 14px">
                                      Total Number of Participants<span style="color: red; font-size: 20px">*</span>
                                    </span>
                                  </template>
                                </v-text-field>
                              </v-flex> -->

                              <v-flex
                                pl-3
                                xs11
                                lg3
                                pt-6
                                pb-6
                                text-left
                                align-self-center
                              >
                                <v-autocomplete
                                  outlined
                                  dense
                                  color="black"
                                  :items="applicantcategorys"
                                  v-model="applicantCategory"
                                  item-text="name"
                                  item-value="name"
                                  item-color="#FF1313"
                                  hide-details="auto"
                                  clearable
                                  class="custom-autocomplete mainfont"
                                >
                                  <template v-slot:label>
                                    <span
                                      class="custom-label-text"
                                      style="color: black; font-size: 14px"
                                    >
                                      Applicant Category
                                      <span style="color: red; font-size: 20px"
                                        >*</span
                                      >
                                    </span>
                                  </template>
                                  <template v-slot:append>
                                    <v-icon color="black"
                                      >mdi-chevron-down</v-icon
                                    >
                                  </template>
                                </v-autocomplete>
                              </v-flex>
                              <v-flex
                                v-if="applicantCategory === 'Students'"
                                pl-3
                                xs11
                                lg3
                                pt-6
                                pb-6
                                text-left
                                align-self-center
                              >
                                <v-autocomplete
                                  outlined
                                  dense
                                  color="black"
                                  :items="studentCategories"
                                  v-model="studentCategory"
                                  item-text="name"
                                  item-value="name"
                                  item-color="#FF1313"
                                  hide-details="auto"
                                  clearable
                                  class="custom-autocomplete mainfont"
                                >
                                  <template v-slot:label>
                                    <span
                                      class="custom-label-text"
                                      style="color: black; font-size: 14px"
                                    >
                                      Student Category
                                      <span style="color: red; font-size: 20px"
                                        >*</span
                                      >
                                    </span>
                                  </template>
                                  <template v-slot:append>
                                    <v-icon color="black"
                                      >mdi-chevron-down</v-icon
                                    >
                                  </template>
                                </v-autocomplete>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12>
                            <v-layout wrap>
                              <v-flex
                                v-if="applicantCategory === 'Students'"
                                pl-3
                                xs11
                                lg3
                                pt-6
                                pb-6
                                text-left
                                align-self-center
                              >
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      dense
                                      color="#283e51"
                                      outlined
                                      background-color="white"
                                      v-model="instCode"
                                      hide-details="auto"
                                      v-on="on"
                                      @input="validateInstCode"
                                    >
                                      <template v-slot:label>
                                        <span
                                          class="custom-label-text"
                                          style="color: black; font-size: 14px"
                                        >
                                          Institution/Organisation code
                                          <span
                                            style="color: red; font-size: 20px"
                                            >*</span
                                          >
                                        </span>
                                      </template>
                                    </v-text-field>
                                  </template>
                                  <span
                                    >Please enter the Registration number of
                                    your Institution/Organisation</span
                                  >
                                </v-tooltip>
                              </v-flex>
                              <v-flex
                                pl-3
                                xs11
                                lg3
                                pt-6
                                pb-6
                                text-left
                                align-self-center
                              >
                                <v-text-field
                                  type="text"
                                  dense
                                  color="#283e51"
                                  outlined
                                  background-color="white"
                                  v-model="instName"
                                  hide-details="auto"
                                >
                                  <template v-slot:label>
                                    <span
                                      class="custom-label-text"
                                      style="color: black; font-size: 13px"
                                    >
                                      Name of the Institution/Organisation
                                      <span style="color: red; font-size: 20px"
                                        >*</span
                                      >
                                    </span>
                                  </template>
                                </v-text-field>
                              </v-flex>
                              <v-flex
                                pl-3
                                xs11
                                lg3
                                pt-6
                                pb-6
                                text-left
                                align-self-center
                              >
                                <v-autocomplete
                                  outlined
                                  dense
                                  color="black"
                                  :items="institutionTypes"
                                  v-model="instType"
                                  item-text="name"
                                  item-value="name"
                                  item-color="#FF1313"
                                  hide-details="auto"
                                  clearable
                                  class="custom-autocomplete mainfont"
                                >
                                  <template v-slot:label>
                                    <span
                                      class="custom-label-text"
                                      style="color: black; font-size: 14px"
                                    >
                                      Type of Institution
                                      <span style="color: red; font-size: 20px"
                                        >*</span
                                      >
                                    </span>
                                  </template>
                                  <template v-slot:append>
                                    <v-icon color="black"
                                      >mdi-chevron-down</v-icon
                                    >
                                  </template>
                                </v-autocomplete>
                              </v-flex>

                              <v-flex
                                pl-3
                                xs11
                                lg3
                                pt-9
                                pr-3
                                pb-6
                                text-left
                                align-self-center
                              >
                                <v-text-field
                                  color="#283e51"
                                  outlined
                                  background-color="white"
                                  v-model="instAddress"
                                  hide-details="auto"
                                >
                                  <template v-slot:label>
                                    <span
                                      class="custom-label-text"
                                      style="color: black; font-size: 13px"
                                    >
                                      Address of the Institution/Organisation
                                      <span style="color: red; font-size: 20px"
                                        >*</span
                                      >
                                    </span>
                                  </template>
                                </v-text-field>
                              </v-flex>
                              <v-flex
                                v-if="applicantCategory === 'Students'"
                                pl-3
                                xs11
                                lg3
                                pt-3
                                pb-6
                                text-left
                                align-self-center
                              >
                                <v-text-field
                                  type="number"
                                  :rules="mobNumberRules"
                                  dense
                                  color="#283e51"
                                  outlined
                                  class="inputPrice"
                                  v-model="instMobileNo"
                                >
                                  <template v-slot:label>
                                    <span
                                      class="custom-label-text"
                                      style="color: black; font-size: 14px"
                                    >
                                      Institution Mobile Number
                                      <span style="color: red; font-size: 20px"
                                        >*</span
                                      >
                                    </span>
                                  </template>
                                </v-text-field>
                              </v-flex>
                              <v-flex
                                v-if="applicantCategory !== 'Students'"
                                pl-3
                                xs11
                                lg3
                                pt-12
                                pr-3
                                pb-4
                                text-left
                                align-self-center
                              >
                                <v-text-field
                                  type="number"
                                  :rules="mobNumberRules"
                                  dense
                                  color="#283e51"
                                  outlined
                                  class="inputPrice"
                                  v-model="instMobileNo"
                                >
                                  <template v-slot:label>
                                    <span
                                      class="custom-label-text"
                                      style="color: black; font-size: 14px"
                                    >
                                      Institution Mobile Number
                                      <span style="color: red; font-size: 20px"
                                        >*</span
                                      >
                                    </span>
                                  </template>
                                </v-text-field>
                              </v-flex>
                              <v-flex
                                pl-3
                                xs11
                                lg3
                                pt-3
                                pb-6
                                text-left
                                align-self-center
                              >
                                <v-text-field
                                  type="number"
                                  dense
                                  clearable
                                  outlined
                                  class="inputPrice"
                                  v-model="instTeleNo"
                                  :rules="[
                                    (v) => !!v || 'Phone number is required',
                                    (v) =>
                                      /^[0-9]{11}$/.test(v) ||
                                      'Phone number must have 11 digits',
                                  ]"
                                >
                                  <template v-slot:label>
                                    <span
                                      class="custom-label-text"
                                      style="color: black; font-size: 14px"
                                    >
                                      Institution Phone Number
                                      <span style="color: red; font-size: 20px"
                                        >*</span
                                      >
                                    </span>
                                  </template>
                                </v-text-field>
                              </v-flex>

                              <v-flex
                                pl-3
                                xs11
                                lg3
                                pt-3
                                pb-6
                                text-left
                                align-self-center
                              >
                                <v-text-field
                                  :rules="emailRules"
                                  dense
                                  color="#283e51"
                                  outlined
                                  background-color="white"
                                  v-model="instEmail"
                                >
                                  <template v-slot:label>
                                    <span
                                      class="custom-label-text"
                                      style="color: black; font-size: 14px"
                                    >
                                      Institution Email<span
                                        style="color: red; font-size: 20px"
                                        >*</span
                                      >
                                    </span>
                                  </template>
                                </v-text-field>
                              </v-flex>
                              <v-flex
                                pl-3
                                xs11
                                lg3
                                pt-3
                                pb-6
                                pr-3
                                text-left
                                align-self-center
                              >
                                <v-autocomplete
                                  dense
                                  color="#283e51"
                                  outlined
                                  :items="instDist"
                                  v-model="instDistrict"
                                  background-color="white"
                                >
                                  <template v-slot:label>
                                    <span
                                      class="custom-label-text"
                                      style="color: black; font-size: 14px"
                                    >
                                      Institution District<span
                                        style="color: red; font-size: 20px"
                                        >*</span
                                      >
                                    </span>
                                  </template>
                                </v-autocomplete>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex pa-4 xs12 text-center>
                <v-card>
                  <template>
                    <div>
                      <v-layout wrap>
                        <v-flex xs12 text-center>
                          <v-card>
                            <v-layout wrap justify-center>
                              <v-flex
                                pl-4
                                pt-2
                                pb-2
                                xs12
                                text-left
                                style="background-color: black"
                              >
                                <span style="font-size: 20px; color: white"
                                  >Details of Head of Institution/Organization
                                </span>
                              </v-flex>
                              <v-flex xs12>
                                <v-layout wrap>
                                  <v-flex
                                    pl-3
                                    xs3
                                    pt-6
                                    pb-6
                                    text-left
                                    align-self-center
                                  >
                                    <v-text-field
                                      dense
                                      color="#283e51"
                                      outlined
                                      type="text"
                                      background-color="white"
                                      v-model="instHeadName"
                                    >
                                      <template v-slot:label>
                                        <span
                                          class="custom-label-text"
                                          style="color: black; font-size: 14px"
                                          >Name of Head of
                                          Institution/Organisation
                                          <span
                                            style="color: red; font-size: 20px"
                                            >*</span
                                          >
                                        </span>
                                      </template>
                                    </v-text-field>
                                  </v-flex>

                                  <v-flex
                                    pl-3
                                    xs3
                                    pt-6
                                    pb-6
                                    text-left
                                    align-self-center
                                  >
                                    <v-text-field
                                      dense
                                      color="#283e51"
                                      outlined
                                      background-color="white"
                                      v-model="headDesignation"
                                    >
                                      <template v-slot:label>
                                        <span
                                          class="custom-label-text"
                                          style="color: black; font-size: 14px"
                                          >Designation in Institute
                                          /Organisation
                                          <span
                                            style="color: red; font-size: 20px"
                                            >*</span
                                          >
                                        </span>
                                      </template>
                                    </v-text-field>
                                  </v-flex>

                                  <v-flex
                                    pl-3
                                    xs3
                                    pt-6
                                    pb-6
                                    text-left
                                    align-self-center
                                  >
                                    <v-autocomplete
                                      outlined
                                      dense
                                      color="black"
                                      :items="idcardtypes"
                                      v-model="idCardType"
                                      item-text="name"
                                      item-value="name"
                                      item-color="#FF1313"
                                      clearable
                                      class="custom-autocomplete mainfont"
                                    >
                                      <template v-slot:label>
                                        <span
                                          class="custom-label-text"
                                          style="color: black; font-size: 14px"
                                          >Id card type<span
                                            style="color: red; font-size: 20px"
                                            >*</span
                                          >
                                        </span>
                                      </template>
                                      <template v-slot:append>
                                        <v-icon color="black"
                                          >mdi-chevron-down</v-icon
                                        >
                                      </template>
                                    </v-autocomplete>
                                  </v-flex>

                                  <v-flex
                                    v-if="idCardType === 'Election Id Card'"
                                    pl-3
                                    xs3
                                    pt-6
                                    pb-6
                                    pr-3
                                    text-left
                                    align-self-center
                                  >
                                    <v-text-field
                                      dense
                                      color="#283e51"
                                      outlined
                                      background-color="white"
                                      v-model="idNo"
                                    >
                                      <template v-slot:label>
                                        <span
                                          class="custom-label-text"
                                          style="color: black; font-size: 14px"
                                          >Id Card Number
                                          <span
                                            style="color: red; font-size: 20px"
                                            >*</span
                                          >
                                        </span>
                                      </template>
                                    </v-text-field>
                                  </v-flex>
                                  <v-flex
                                    v-if="idCardType === 'Driving License'"
                                    pl-3
                                    xs3
                                    pt-6
                                    pb-6
                                    pr-3
                                    text-left
                                    align-self-center
                                  >
                                    <v-text-field
                                      dense
                                      color="#283e51"
                                      outlined
                                      background-color="white"
                                      v-model="idNo"
                                    >
                                      <template v-slot:label>
                                        <span
                                          class="custom-label-text"
                                          style="color: black; font-size: 14px"
                                          >Id Card Number
                                          <span
                                            style="color: red; font-size: 20px"
                                            >*</span
                                          >
                                        </span>
                                      </template>
                                    </v-text-field>
                                  </v-flex>
                                  <v-flex
                                    v-if="idCardType === 'Government ID card'"
                                    pl-3
                                    xs3
                                    pt-6
                                    pb-6
                                    pr-3
                                    text-left
                                    align-self-center
                                  >
                                    <v-text-field
                                      dense
                                      color="#283e51"
                                      outlined
                                      background-color="white"
                                      v-model="idNo"
                                    >
                                      <template v-slot:label>
                                        <span
                                          class="custom-label-text"
                                          style="color: black; font-size: 14px"
                                          >Id Card Number
                                          <span
                                            style="color: red; font-size: 20px"
                                            >*</span
                                          >
                                        </span>
                                      </template>
                                    </v-text-field>
                                  </v-flex>
                                  <v-flex
                                    v-if="idCardType === 'Aadhaar Card'"
                                    pl-3
                                    xs3
                                    pt-6
                                    pb-6
                                    pr-3
                                    text-left
                                    align-self-center
                                  >
                                    <v-text-field
                                      type="number"
                                      dense
                                      color="#283e51"
                                      outlined
                                      class="inputPrice"
                                      background-color="white"
                                      v-model="idNo"
                                      :rules="aadharNumberRules"
                                    >
                                      <template v-slot:label>
                                        <span
                                          class="custom-label-text"
                                          style="color: black; font-size: 14px"
                                          >Aadhar Number
                                          <span
                                            style="color: red; font-size: 20px"
                                            >*</span
                                          >
                                        </span>
                                      </template>
                                    </v-text-field>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                          </v-card>
                        </v-flex>
                      </v-layout>
                    </div>
                  </template>
                </v-card>
              </v-flex>

              <v-flex pa-4 xs12 text-center>
                <v-card>
                  <template>
                    <div>
                      <v-layout wrap>
                        <v-flex
                          pl-4
                          pt-2
                          pb-2
                          text-left
                          style="background-color: black"
                        >
                          <v-layout wrap justify-start>
                            <v-flex xs5 pt-2 align-self-center>
                              <span style="font-size: 20px; color: white">
                                Group Leader/Teaching Staff Details </span
                              >&nbsp;
                              <v-btn
                                small
                                color="#68D389"
                                @click="addLeaderDetails()"
                              >
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                      <v-layout
                        wrap
                        justify-start
                        style="background-color: white"
                      >
                        <v-card flat>
                          <v-layout wrap>
                            <v-flex
                              pt-1
                              v-for="(item, i) in leaderDetails"
                              :key="i"
                            >
                              <v-layout wrap pt-2>
                                <v-flex lg3 pt-8 pt-lg-0 pa-3>
                                  <v-text-field
                                    color="black"
                                    v-model="item.leaderName"
                                    dense
                                    outlined
                                    label="Name"
                                  >
                                    <template v-slot:label>
                                      <span
                                        class="custom-label-text"
                                        style="color: black; font-size: 14px"
                                      >
                                        Name<span
                                          style="color: red; font-size: 20px"
                                          >*</span
                                        ></span
                                      >
                                    </template>
                                  </v-text-field>
                                </v-flex>
                                <v-flex lg3 pt-8 pt-lg-0 pa-3>
                                  <v-text-field
                                    color="black"
                                    v-model="item.leaderAddress1"
                                    dense
                                    outlined
                                    label="Designation"
                                  >
                                    <template v-slot:label>
                                      <span
                                        class="custom-label-text"
                                        style="color: black; font-size: 14px"
                                      >
                                        Designation<span
                                          style="color: red; font-size: 20px"
                                          >*</span
                                        ></span
                                      >
                                    </template>
                                  </v-text-field>
                                </v-flex>

                                <v-flex lg3 pt-8 pt-lg-0 pa-3>
                                  <v-text-field
                                    color="black"
                                    v-model="item.leaderAddress2"
                                    dense
                                    outlined
                                    label="Address2"
                                  >
                                    <template v-slot:label>
                                      <span
                                        class="custom-label-text"
                                        style="color: black; font-size: 14px"
                                      >
                                        Address<span
                                          style="color: red; font-size: 20px"
                                          >*</span
                                        ></span
                                      >
                                    </template>
                                  </v-text-field>
                                </v-flex>
                                <v-flex lg3 pt-8 pt-lg-0 pa-3>
                                  <v-text-field
                                    color="black"
                                    v-model="item.telephone"
                                    type="number"
                                    dense
                                    outlined
                                    label="Telephone"
                                    class="inputPrice"
                                    clearable
                                  >
                                    <template v-slot:label>
                                      <span
                                        class="custom-label-text"
                                        style="color: black; font-size: 14px"
                                      >
                                        Telephone<span
                                          style="color: red; font-size: 20px"
                                          >*</span
                                        ></span
                                      >
                                    </template>
                                  </v-text-field>
                                </v-flex>
                                <v-flex lg3 pt-8 pt-lg-0 pa-3>
                                  <v-text-field
                                    :rules="mobNumberRules"
                                    v-model="item.leaderPhone"
                                    type="number"
                                    dense
                                    outlined
                                    label="Mobile"
                                    clearable
                                    class="inputPrice"
                                  >
                                    <template v-slot:label>
                                      <span
                                        class="custom-label-text"
                                        style="color: black; font-size: 14px"
                                        >Mobile<span
                                          style="color: red; font-size: 20px"
                                          >*</span
                                        >
                                      </span>
                                    </template>
                                  </v-text-field>
                                </v-flex>

                                <v-flex lg3 pt-8 pt-lg-0 pa-3>
                                  <v-text-field
                                    color="black"
                                    :rules="emailRules"
                                    v-model="item.leaderEmail"
                                    dense
                                    outlined
                                    label="Email"
                                  >
                                    <template v-slot:label>
                                      <span
                                        class="custom-label-text"
                                        style="color: black; font-size: 14px"
                                      >
                                        Email<span
                                          style="color: red; font-size: 20px"
                                          >*</span
                                        >
                                      </span>
                                    </template>
                                  </v-text-field>
                                </v-flex>
                                <v-flex lg3 pt-8 pt-lg-0 pa-3>
                                  <v-autocomplete
                                    outlined
                                    dense
                                    color="black"
                                    :items="gender"
                                    v-model="item.leaderGender"
                                    item-text="name"
                                    item-value="name"
                                    item-color="#FF1313"
                                    clearable
                                    class="custom-autocomplete mainfont"
                                  >
                                    <template v-slot:label>
                                      <span
                                        class="custom-label-text"
                                        style="color: black; font-size: 14px"
                                      >
                                        Gender<span
                                          style="color: red; font-size: 20px"
                                          >*</span
                                        >
                                      </span>
                                    </template>
                                    <template v-slot:append>
                                      <v-icon color="black"
                                        >mdi-chevron-down</v-icon
                                      >
                                    </template>
                                  </v-autocomplete>
                                </v-flex>
                                <v-flex lg2>
                                  <center>
                                    <v-btn
                                      dark
                                      style="background-color: #68d389"
                                      dense
                                      @click="removeleaderdetails(i)"
                                    >
                                      Remove
                                    </v-btn>
                                  </center>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-card>
                      </v-layout>
                    </div>
                  </template>
                </v-card>
              </v-flex>

              <v-flex pa-4 xs12 text-center>
                <v-card>
                  <template>
                    <div>
                      <v-layout wrap>
                        <v-flex
                          pl-4
                          pt-2
                          pb-2
                          text-left
                          style="background-color: black"
                        >
                          <v-layout wrap justify-start>
                            <v-flex xs4 pt-2 align-self-center>
                              <span style="font-size: 20px; color: white">
                                Details of Participants</span
                              >&nbsp;
                              <v-btn small color="#68D389" @click="addValues()">
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                      <v-layout
                        wrap
                        justify-start
                        style="background-color: white"
                      >
                        <v-card flat>
                          <v-layout wrap>
                            <v-flex
                              pt-1
                              v-for="(item, i) in memberDetails"
                              :key="i"
                            >
                              <v-layout wrap pt-2>
                                <v-flex lg3 pt-8 pt-lg-0 pa-3>
                                  <v-text-field
                                    color="black"
                                    v-model="item.memberName"
                                    dense
                                    outlined
                                    label="memberName"
                                  >
                                    <template v-slot:label>
                                      <span
                                        class="custom-label-text"
                                        style="color: black; font-size: 14px"
                                      >
                                        Name of Participant<span
                                          style="color: red; font-size: 20px"
                                          >*</span
                                        >
                                      </span>
                                    </template>
                                  </v-text-field>
                                </v-flex>
                                <v-flex lg3 pt-8 pt-lg-0 pa-3>
                                  <v-menu
                                    ref="menu2"
                                    v-model="item.menu2"
                                    :close-on-content-click="false"
                                    max-width="290"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        clearable
                                        dense
                                        outlined
                                        readonly
                                        hide-details="auto"
                                        :value="formatDate(item.memberDob)"
                                        v-bind="attrs"
                                        background-color="white"
                                        v-on="on"
                                        @click:clear="dob = null"
                                        style="color: black"
                                        @blur="date = parseDate(dateFormatted)"
                                      >
                                        <template v-slot:append>
                                          <v-icon color="black"
                                            >mdi-chevron-down</v-icon
                                          >
                                        </template>
                                        <template v-slot:label>
                                          <span
                                            class="custom-label-text"
                                            style="
                                              color: black;
                                              font-size: 14px;
                                            "
                                            >Date Of Birth</span
                                          >
                                        </template>
                                      </v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="item.memberDob"
                                      color="#13736f"
                                      :max="calculateMaxDate()"
                                      @input="formatDate"
                                    ></v-date-picker>
                                  </v-menu>
                                </v-flex>
                                <v-flex lg3 pt-8 pt-lg-0 pa-3>
                                  <v-autocomplete
                                    outlined
                                    dense
                                    color="black"
                                    :items="gender"
                                    v-model="item.memberGender"
                                    item-text="name"
                                    item-value="name"
                                    item-color="#FF1313"
                                    clearable
                                    class="custom-autocomplete mainfont"
                                  >
                                    <template v-slot:label>
                                      <span
                                        class="custom-label-text"
                                        style="color: black; font-size: 14px"
                                      >
                                        Gender<span
                                          style="color: red; font-size: 20px"
                                          >*</span
                                        >
                                      </span>
                                    </template>
                                    <template v-slot:append>
                                      <v-icon color="black"
                                        >mdi-chevron-down</v-icon
                                      >
                                    </template>
                                  </v-autocomplete>
                                </v-flex>
                                <v-flex lg3 pb-3>
                                  <v-btn
                                    dark
                                    style="background-color: #68d389"
                                    dense
                                    @click="removeVehicle(i)"
                                  >
                                    Remove
                                  </v-btn>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                          <v-layout wrap pt-3>
                            <v-flex xs4 pl-3>
                              <v-text-field
                                dense
                                color="#283e51"
                                outlined
                                background-color="white"
                                v-model="calculatedGenderCounts.male"
                                disabled
                              >
                                <template v-slot:label>
                                  <span
                                    class="custom-label-text"
                                    style="color: black; font-size: 14px"
                                    >Male Count
                                    <span style="color: red; font-size: 20px"
                                      >*</span
                                    >
                                  </span>
                                </template>
                              </v-text-field>
                            </v-flex>
                            <v-flex xs4 pl-3>
                              <v-text-field
                                dense
                                color="#283e51"
                                outlined
                                background-color="white"
                                v-model="calculatedGenderCounts.female"
                                disabled
                              >
                                <template v-slot:label>
                                  <span
                                    class="custom-label-text"
                                    style="color: black; font-size: 14px"
                                    >Female Count
                                    <span style="color: red; font-size: 20px"
                                      >*</span
                                    >
                                  </span>
                                </template>
                              </v-text-field>
                            </v-flex>
                            <v-flex xs4 pl-3 pr-3>
                              <v-text-field
                                dense
                                color="#283e51"
                                outlined
                                background-color="white"
                                v-model="calculatedGenderCounts.transgender"
                                disabled
                              >
                                <template v-slot:label>
                                  <span
                                    class="custom-label-text"
                                    style="color: black; font-size: 14px"
                                    >Transgender Count
                                    <span style="color: red; font-size: 20px"
                                      >*</span
                                    >
                                  </span>
                                </template>
                              </v-text-field>
                            </v-flex>
                            <v-flex lg12>
                              <span style="color: red"
                                >*Note:Vegetarian food is provided in the
                                Camps.</span
                              >
                              <!-- <div>
                                <span>Male Count: {{ calculatedGenderCounts.male }}</span>
                                <span>Female Count: {{ calculatedGenderCounts.female }}</span>
                                <span>Transgender Count: {{ calculatedGenderCounts.transgender }}</span>
                              </div> -->
                            </v-flex>
                          </v-layout>
                        </v-card>
                      </v-layout>
                    </div>
                  </template>
                </v-card>
              </v-flex>

              <v-flex pa-4 xs12 text-center>
                <v-card>
                  <v-layout wrap>
                    <v-flex xs12 text-center>
                      <v-card>
                        <v-layout wrap justify-center>
                          <v-flex xs12>
                            <template>
                              <div>
                                <v-layout wrap justify-center>
                                  <v-flex
                                    pl-4
                                    pt-2
                                    pb-2
                                    xs12
                                    text-left
                                    style="background-color: black"
                                  >
                                    <span style="font-size: 20px; color: white">
                                      Scanned Documents
                                    </span>
                                  </v-flex>
                                  <v-flex xs12 text-center pa-4>
                                    <span style="font-size: 20px; color: black">
                                      (File size cannot exceed 100 KB)
                                    </span>
                                  </v-flex>
                                  <v-flex xs11 pb-6>
                                    <v-layout wrap justify-center>
                                      <v-flex xs6 text-left>
                                        <label for="idProof">
                                          <span
                                            >ID proof of Head of institution /
                                            Organisation
                                            <span
                                              style="
                                                color: red;
                                                font-size: 23px;
                                              "
                                              >*</span
                                            >
                                          </span>
                                        </label>
                                      </v-flex>
                                      <v-flex xs6>
                                        <input
                                          type="file"
                                          id="idProof"
                                          ref="idProof"
                                          accept=".pdf, .jpeg, .png"
                                          @change="handleFileChange('idProof')"
                                        />
                                      </v-flex>

                                      <v-flex xs6 pt-4 text-left>
                                        <label for="reqLetter" text-left>
                                          <span
                                            >Request Letter from Head of
                                            Institution/ Organisation
                                            <span
                                              style="
                                                color: red;
                                                font-size: 23px;
                                              "
                                              >*</span
                                            >
                                          </span>
                                        </label>
                                      </v-flex>
                                      <v-flex xs6 pt-4>
                                        <input
                                          type="file"
                                          id="reqLetter"
                                          ref="reqLetter"
                                          accept=".pdf, .jpeg, .png"
                                          @change="
                                            handleFileChange('reqLetter')
                                          "
                                        />
                                      </v-flex>

                                      <v-flex xs6 pt-4 text-left>
                                        <label for="participantfile" text-left>
                                          <span
                                            >List of participants duly signed by
                                            Head of Institution/ Organisation
                                            <span
                                              style="
                                                color: red;
                                                font-size: 23px;
                                              "
                                              >*</span
                                            >
                                          </span>
                                        </label>
                                      </v-flex>
                                      <v-flex xs6 pt-4>
                                        <input
                                          type="file"
                                          id="participantfile"
                                          ref="participantfile"
                                          accept=".pdf, .jpeg, .png"
                                          @change="
                                            handleFileChange('participantfile')
                                          "
                                        />
                                      </v-flex>

                                      <v-flex xs6 pt-4 text-left>
                                        <label for="leaderIdProof" text-left>
                                          <span
                                            >ID Proof of one of the Group
                                            Leader/Teaching staff
                                            <span
                                              style="
                                                color: red;
                                                font-size: 23px;
                                              "
                                              >*</span
                                            >
                                          </span>
                                        </label>
                                      </v-flex>
                                      <v-flex xs6 pt-4>
                                        <input
                                          type="file"
                                          id="leaderIdProof"
                                          ref="leaderIdProof"
                                          accept=".pdf, .jpeg, .png"
                                          @change="
                                            handleFileChange('leaderIdProof')
                                          "
                                        />
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </div>
                            </template>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>

              <v-flex pa-4 xs12 text-center>
                <v-card>
                  <v-layout wrap justify-center>
                    <v-flex
                      pl-4
                      pt-2
                      pb-2
                      xs12
                      text-left
                      style="background-color: black"
                    >
                      <span style="font-size: 20px; color: white">
                        Undertaking
                      </span>
                    </v-flex>
                    <v-flex pt-3 xs1>
                      <v-checkbox v-model="isChecked"></v-checkbox>
                    </v-flex>

                    <v-flex xs10 pb-5 text-left>
                      <v-layout wrap pt-4>
                        <v-flex
                          xs12
                          pt-4
                          v-for="(content, index) in agreementContent"
                          :key="index"
                        >
                          <span>{{ content }}</span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs9 pb-10>
                      <v-layout wrap justify-start>
                        <v-flex xs4>
                          <v-text-field
                            type="text"
                            dense
                            disabled
                            color="#283e51"
                            outlined
                            background-color="white"
                            v-model="applicantname"
                          >
                            <template v-slot:label>
                              <span
                                class="custom-label-text"
                                style="color: black; font-size: 13px"
                              >
                                Name of the applicant
                                <span style="color: red; font-size: 20px"
                                  >*</span
                                >
                              </span>
                            </template>
                          </v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>

              <v-flex xs12 pb-10>
                <v-layout wrap justify-end>
                  <v-flex xs2 pr-4>
                    <v-btn
                      @click="openFormInNewWindow"
                      color="black"
                      block
                      type="submit"
                    >
                      <span style="color: white">Test Submit</span>
                    </v-btn>
                  </v-flex>
                  <v-flex xs2 pr-4>
                    <v-btn
                      @click="add()"
                      color="black"
                      block
                      type="submit"
                      :disabled="!isChecked || status23 == true"
                    >
                      <span style="color: white">SAVE </span>
                    </v-btn>
                  </v-flex>
                  <v-flex xs2 pr-4 v-if="status23">
                    <v-btn @click="getPreview()" color="black" block>
                      <span style="color: white">PREVIEW</span
                      ><v-icon color="white"> mdi-download </v-icon>
                    </v-btn>
                  </v-flex>
                  <v-flex xs2 pr-4>
                    <v-btn
                      @click="submitdialog = true"
                      color="black"
                      block
                      type="submit"
                      :disabled="checkvar == false ? true : false"
                    >
                      <span style="color: white">SUBMIT</span>
                    </v-btn>
                  </v-flex>
                </v-layout>
                <v-dialog
                  :retain-focus="true"
                  persistent
                  v-model="submitdialog"
                  max-width="800px"
                >
                  <v-card>
                    <v-card-title
                      class="mainfont"
                      style="
                        color: black;
                        font-size: 18px;
                        font-weight: lighter;
                      "
                    >
                      Are you sure you want to submit? Once submitted, you won't
                      be able to edit.</v-card-title
                    >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="black"
                        text
                        @click="submitdialog = false"
                        class="mainfont"
                        >Cancel</v-btn
                      >
                      <v-btn
                        color="green"
                        class="mainfont"
                        text
                        @click="submit()"
                        >Yes</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog
                  :retain-focus="true"
                  persistent
                  v-model="memberdialog"
                  max-width="350px"
                >
                  <v-card>
                    <v-layout wrap justify-center>
                      <v-flex xs10 pt-4 text-center>
                        <span
                          class="mainfont"
                          style="
                            color: black;
                            font-size: 18px;
                            font-weight: lighter;
                          "
                        >
                          {{ memberCountMsg }}
                        </span>
                      </v-flex>
                      <v-flex text-center>
                        <v-btn
                          color="green"
                          class="mainfont"
                          text
                          @click="submit2()"
                          >OK</v-btn
                        >
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-dialog>
                <v-dialog
                  :retain-focus="true"
                  persistent
                  v-model="bookdialog"
                  max-width="350px"
                >
                  <v-card>
                    <v-layout wrap justify-center>
                      <v-flex xs10 pt-4 text-center>
                        <span
                          class="mainfont"
                          style="
                            color: black;
                            font-size: 18px;
                            font-weight: lighter;
                          "
                        >
                          {{ message }}
                        </span>
                      </v-flex>
                      <v-flex text-center>
                        <v-btn
                          color="green"
                          class="mainfont"
                          text
                          @click="submit3()"
                          >OK</v-btn
                        >
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-dialog>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  components: {},
  data() {
    // const today = new Date().toISOString().substr(0, 10);
    return {
      /* dateFormatted: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)), */
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      allowedFileTypes: ["application/pdf", "image/jpeg", "image/png"],
      Reserveddates1: [],
      modal: false,
      idProof: null,
      dateFormatted: "",
      reqLetter: null,
      participantfile: null,
      status23: false,
      leaderIdProof: null,
      gender: ["Male", "Female", "Transgender"],
      foodpre: ["Vegetarian", "Non-vegetarian"],
      memberDetails: [],
      leaderName: null,
      availableSlots2: "",
      memberGender: ["Male", "Female", "Transgender"],
      leaderAddress1: null,
      leaderAddress2: null,
      telephone: null,
      idCardType: null,
      submitdialog: false,
      emailRules: [
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      ],
      loading: true,
      leaderPhone: null,
      leaderGender: null,
      leaderEmail: null,
      district: null,
      instHeadName: null,
      headDesignation: null,
      idCardTypeidCardType: null,
      idNo: null,
      genderCounts: {
        male: 0,
        female: 0,
        transgender: 0,
      },
      idcardtypes: [
        "Election Id Card",
        "Driving License",
        "Aadhaar Card",
        "Government ID card",
      ],
      instName: null,
      instCode: null,
      instType: null,
      instDistrict: null,
      instAddress: null,
      instTeleNo: "",
      instMobileNo: null,
      instEmail: null,
      institutionTypes: ["Government", "Aided", "Private", "Others"],
      instDist: [
        "Thiruvananthapuram",
        "Kollam",
        "Pathanamthitta",
        "Alappuzha",
        "Kottayam",
        "Idukki",
        "Ernakulam",
        "Thrissur",
        "Palakkad",
        "Malappuram",
        "Kozhikode",
        "Wayanad",
        "Kannur",
        "Kasaragod",
      ],

      startDate: null,
      // startDate: new Date().toISOString().substr(0, 10),

      menu2: false,
      showSnackBar: false,
      isChecked: false,
      appId1: "",
      appLoading: false,
      memberdialog: false,
      bookdialog: false,
      dist: this.$route.query.district,

      msg: "",
      expandSection: false,
      name: null,
      barlist: [],
      agreementContent: [
        "I, hereby undertake on behalf of the Institution/Organization/Team that myself and all the Team members taking part in the Nature Camp shall abide by the conditions as per the existing laws pertaining to Forests & Wildlife, orders of the Forest Officers/competent authorities during the Nature Camp and maintain discipline throughout the course of the Nature Camp.",
        "I, hereby declare that the entries made by me, for the members of the team, in the Application Form are complete and true to the best of my knowledge, belief, and information.",
        "I, hereby undertake to present the original documents/ID proof and note that every members should carry their own ID for verification immediately upon demand by the competent authorities, as and when required.",
        "I, hereby declare that I and or my team/group members shall be solely responsible for our involvement in any kind of unlawful activities whether inside or outside the premises at the selected location of the Nature Camp, and shall be liable for punishment, if any, as per the law of the land and shall be liable to compensate for any losses caused, as decided by the DFO/WLW/CWW.",
        "It is hereby stated that we are aware that Kerala Forest Department can withdraw/cancel the nature camp allotted, for whatsoever reasons, including administrative exigencies of the department or any failures/lack of bindings to the terms and conditions of the Department, from our side as participants.",
        "I declare that my team members and I will strictly adhere to the code of conduct, as issued by the Competent Authority for the Nature Camp Programme at the selected location.",
        "I declare that I am aware of the fact that, even if a Nature Camp is allotted at selected location to me and or my team, the Camp could be canceled at any stage, by the Divisional Forest Officer/Wildlife Warden or any Competent Authority and I have no objection and I and or my team members and or my Head of Institution/Organisation will not raise any dispute against this.",
        "*This is an electronic generated statement and does not require a signature. By clicking submit it will be assumed that you accept and adhere to all the conditions listed above. Click submit if you wish to proceed .",
      ],
      // agreementContent: [
      //   "This is an electronic generated statement and does not require a signature. By clicking submit it will be assumed that you accept and adhere to all the conditions listed above. Click submit if you wish to proceed ."
      // ],
      list: {},
      selectedDistrict: null,
      districts: [
        "Thiruvananthapuram",
        "Kollam",
        "Pathanamthitta",
        "Alappuzha",
        "Kottayam",
        "Idukki",
        "Ernakulam",
        "Thrissur",
        "Palakkad",
        "Malappuram",
        "Kozhikode",
        "Wayanad",
        "Kannur",
        "Kasaragod",
      ],
      campDuration: "",
      memberCountMsg: "",
      menu1: false,
      male: null,
      availableSlots: {},
      getdetailsdata:"",
      memberCount: null,
      maleCount: 0,
      femaleCount: 0,
      transCount: 0,
      message: null,
      checkvar: false,
      leaderDetails: [],
      applicantCategory: "",
      studentCategory: "",
      applicantname: null,
      applicantcategorys: [
        "Students",
        "Media Personnel",
        "Corporation Councilors,Muncipal Councilors,Jilla Panchayat Members,Block Panchayat Members And Grama Panchayt Members",
        "Members Of Charitable, Non-governmental and Youth Organizations",
      ],
      studentCategories: [
        "UP students",
        "HS students",
        "HSS students",
        "College students",
      ],
    };
  },
  watch: {
    startDate() {
      this.checkAvailability();
    },
    campDuration() {
      this.checkAvailabilityCamp();
    },
    isChecked() {
      if (this.isChecked == true) {
        this.checkvar = false;
      }
    },
    // memberCount() {
    //   this.checkAvailability2();
    // },
    // totalParticipants() {
    //   this.checkAvailability2();
    // },
  },
  created() {
    this.dist = this.$route.query.district;
  },
  computed: {
    calculatedGenderCounts() {
      const counts = { male: 0, female: 0, transgender: 0 };

      this.memberDetails.forEach((item) => {
        const selectedGender = item.memberGender;
        if (selectedGender === "Male") {
          counts.male++;
        } else if (selectedGender === "Female") {
          counts.female++;
        } else if (selectedGender === "Transgender") {
          counts.transgender++;
        }
      });

      return counts;
    },
    // allowedDates() {
    //   const hiddenRanges = [
    //     { start: new Date('2024-01-01'), end: new Date('2024-01-05') },
    //     { start: new Date('2024-01-08'), end: new Date('2024-01-15') },
    //     { start: new Date('2024-02-17'), end: new Date('2024-02-25') },
    //     { start: new Date('2024-03-10'), end: new Date('2024-03-20') }
    //   ];
    //   const isDateAllowed = date => {
    //     for (const range of hiddenRanges) {
    //       if (date >= range.start && date <= range.end) {
    //         return false;
    //       }
    //     }
    //     return true;
    //   };
    //   return date => isDateAllowed(new Date(date));
    // },

    formattedStartDate() {
      if (this.startDate) {
        const [year, month, day] = this.startDate.split("-");
        return `${day}-${month}-${year}`;
      }
      return null;
    },

    formattedDate() {
      if (this.item.memberDob) {
        const date = new Date(this.item.memberDob);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
      }
      return null;
    },
    // totalParticipants: {
    //   get() {
    //     return (
    //       parseInt(this.maleCount) +
    //       parseInt(this.femaleCount) +
    //       parseInt(this.transCount)
    //     );
    //   },
    //   set() {
    //     console.log("Total Participants cannot be edited directly.");
    //   },
    // },
    minDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = today.getMonth() + 1;
      const day = today.getDate();
      return `${year}-${month < 10 ? "0" : ""}${month}-${
        day < 10 ? "0" : ""
      }${day}`;
    },
    mobNumberRules() {
      return [
        (value) => !!value || "Mobile number is required",
        (value) =>
          (value && /^[0-9]{10}$/.test(value)) ||
          "Mobile number must be 10 digits",
      ];
    },
    PhoneRules() {
      return [
        (v) => !!v || "Phone number is required",
        (v) => /^[0-9]{10}$/.test(v) || "Phone number must be 10 digits",
      ];
    },
    aadharNumberRules() {
      return [
        (v) => !!v || "Aadhaar Number is required",
        (v) =>
          /^[0-9]{12}$/.test(v) || "Aadhaar Number must be exactly 12 digits",
      ];
    },
  },
  mounted() {
    const username = localStorage.getItem("username");
    if (username) {
      this.applicantname = username;
    }
    this.getReserveddates();
    this.getdetails();
  },

  methods: {
    openFormInNewWindow() {
      // Create a form element
      const form = document.createElement("form");
      form.method = "POST";
      form.action =
        "https://www.stagingetreasury.kerala.gov.in/api/eTreasury/service/ChallanGeneration.php"; // Replace with your actual endpoint URL
      form.target = "_blank"; // Open in a new window or tab

      // Create hidden input fields for 'input_data' and 'input_headers'
      const inputDataField = document.createElement("input");
      inputDataField.type = "hidden";
      inputDataField.name = "input_data";
      inputDataField.value = JSON.stringify({
        HMAC: this.getdetailsdata.HMAC, // Replace with actual HMAC value

        DATA: this.getdetailsdata.DATA,

        // Replace with actual encrypted data
      });

      const inputHeadersField = document.createElement("input");
      inputHeadersField.type = "hidden";
      inputHeadersField.name = "input_headers";
      inputHeadersField.value = JSON.stringify({
        clientId: this.getdetailsdata.clientId,
        authToken: this.getdetailsdata.authToken,
        // Replace with actual auth token
      });

      // Append hidden inputs to the form
      form.appendChild(inputDataField);
      form.appendChild(inputHeadersField);

      // Append form to the body (required to submit it)
      document.body.appendChild(form);

      // Submit the form
      form.submit();

      // Remove the form from the DOM after submission
      document.body.removeChild(form);
    },
    isValidAadharNumber() {
      if (this.idCardType === "Aadhaar Card") {
        return /^[0-9]{12}$/.test(this.idNo);
      }
      return true;
    },
    validateInstCode() {
      this.instCode = this.instCode.replace(/[^a-zA-Z0-9]/g, "");
    },
    calculateMaxDate() {
      const currentDate = new Date();
      currentDate.setFullYear(currentDate.getFullYear() - 5);
      return currentDate.toISOString().split("T")[0];
    },
    getdetails() {
      axios({
        method: "Get",
        url: "/authenticate",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.getdetailsdata = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          console.error("Error fetching reserved dates:", error);
          this.loading = false;
        });
    },
    getReserveddates() {
      axios({
        method: "POST",
        url: "/listblockdates/camp",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          campid: this.$route.query.id,
        },
      })
        .then((response) => {
          this.Reserveddates1 = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          console.error("Error fetching reserved dates:", error);
          this.loading = false;
        });
    },
    // filterAllowedDates(val) {
    //   if (!this.Reserveddates1 || this.Reserveddates1.length === 0) {
    //     return true;
    //   }
    //   const date = new Date(val);
    //   return !this.Reserveddates1.includes(this.formatDate2(date));
    // },
    filterAllowedDates(val) {
      const today = new Date();
      const selectedDate = new Date(val);

      // Calculate the difference in days between today and the selected date
      const diffInDays = Math.floor(
        (selectedDate - today) / (1000 * 60 * 60 * 24)
      );

      // Define the cycle parameters
      const cycleLength = 75; // The length of the full cycle
      const disablePeriod = 15; // Number of days to disable at the beginning and end of each cycle
      const enablePeriod = 60; // Number of days to enable in each cycle

      // Calculate the position within the cycle
      const cyclePosition =
        ((diffInDays % cycleLength) + cycleLength) % cycleLength;

      // Determine if the date should be disabled or enabled based on the current cycle position
      if (
        cyclePosition < disablePeriod ||
        cyclePosition >= disablePeriod + enablePeriod
      ) {
        return false; // Disable dates
      }

      // Check against Reserveddates1
      if (this.Reserveddates1 && this.Reserveddates1.length > 0) {
        const formattedDate = this.formatDate2(selectedDate);
        return !this.Reserveddates1.includes(formattedDate);
      }

      return true; // Enable dates
    },

    formatDate2(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    formatDate(date) {
      if (!date) return null;

      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      return new Date(date).toLocaleDateString("en-GB", options);
    },
    countMale() {
      this.totalParticipants =
        parseInt(this.maleCount) +
        parseInt(this.femaleCount) +
        parseInt(this.transCount);
      if (this.totalParticipants) this.checkAvailability2();
    },
    validatePhoneNumber() {
      const phoneNumber = this.instTeleNo.toString();

      if (phoneNumber.length !== 11) {
        this.msg = "Institution Phone number must be exactly 11 digits.";
        this.showSnackBar = true;
      }
    },
    validateAadhaarNumber(value) {
      if (!value) return "Aadhaar Number is required";
      const isValid = /^\d{12}$/.test(value);
      return isValid || "Aadhaar Number must be exactly 12 digits";
    },
    // filterNonNumericInput() {
    //   this.instHeadName = this.instHeadName.replace(/[0-9]/g, "");
    //   this.headDesignation = this.headDesignation.replace(/[0-9]/g, "");
    // },
    updateFormattedDate() {
      if (this.item.memberDob) {
        const date = new Date(this.item.memberDob);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const formattedDate = `${day}-${month}-${year}`;
        this.item.memberDob = formattedDate;
      }
    },
    // handleFileChange(field) {
    //   this[field] = this.$refs[field].files[0];

    // },
    handleFileChange(field) {
      const file = this.$refs[field].files[0];

      if (!this.allowedFileTypes.includes(file.type)) {
        alert("Only PDF and image files (JPEG, PNG) are allowed.");
        this.$refs[field].value = null;
        return;
      }

      const maxSize = 100 * 1024;
      if (file.size > maxSize) {
        alert("File size cannot exceed 100 KB.");
        this.$refs[field].value = null;
        return;
      }
      this[field] = file;
    },
    validateIdAadhaarNumHeadInst() {
      if (this.idNo && this.idNo.length !== 12) {
        this.msg = "Aadhaar Number must be exactly 12 digits";
        this.showSnackBar = true;
      }
    },
    validateFile(file) {
      return file.size <= 100 * 1024;
    },
    validateMobile() {
      return [
        (value) => !!value || "Mobile number is required",
        (value) =>
          (value && /^[0-9]{10}$/.test(value)) ||
          "Mobile number must be 10 digits",
      ];
    },
    submit3() {
      this.startDate = null;
      this.campDuration = null;
      this.bookdialog = false;
    },
    submit2() {
      this.memberCount = 0;
      this.totalParticipants = 0;
      this.memberdialog = false;
      this.maleCount = 0;
      this.femaleCount = 0;
      this.transCount = 0;
    },
    addLeaderDetails() {
      this.leaderDetails.push({
        leaderName: "",
        leaderGender: "",
        leaderAddress1: "",
        leaderAddress2: "",
        telephone: "",
        leaderPhone: "",
        leaderEmail: "",
      });
      this.leaderGender = "";
      this.leaderName = "";
      this.leaderAddress1 = "";
      this.leaderAddress2 = "";
      this.telephone = "";
      this.leaderPhone = "";
      this.leaderEmail = "";
    },
    removeValues() {
      this.leaderDetails.pop();
    },
    removeleaderdetails(index) {
      this.leaderDetails.splice(index, 1);
    },

    addValues() {
      this.memberDetails.push({
        memberGender: "",
        memberDob: "",
        memberName: "",
      });
    },
    removeVehicle(index) {
      this.memberDetails.splice(index, 1);
    },
    validateEmail() {
      const isValidEmail = this.emailRules.every(
        (rule) => rule(this.instEmail) === true
      );
      return isValidEmail;
    },
    validateEmailleader(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    testsubmit() {
      axios({
        method: "GET",
        url: "/verify-connection",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            const newWindow = window.open("", "_blank");

            // Write the content from the response into the new window
            newWindow.document.open();
            newWindow.document.write(response.data.data);
            newWindow.document.close();
            this.msg = response.data.msg;
            this.showSnackBar = true;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.error(err);
        });
    },
    add() {
      for (let i = 0; i < this.leaderDetails.length; i++) {
        if (!/^[a-zA-Z\s]*$/.test(this.leaderDetails[i].leaderName)) {
          this.msg = "Leader Name contains invalid characters.";
          this.showSnackBar = true;
          return;
        }
        if (!/^[a-zA-Z\s]*$/.test(this.leaderDetails[i].leaderAddress1)) {
          this.msg = "Leader Designation contains invalid characters.";
          this.showSnackBar = true;
          return;
        }
        if (
          !/^[a-zA-Z0-9\s,.\-#()]*$/.test(this.leaderDetails[i].leaderAddress2)
        ) {
          this.msg = "Leader Address contains invalid characters.";
          this.showSnackBar = true;
          return;
        }
        if (!this.validateEmailleader(this.leaderDetails[i].leaderEmail)) {
          this.msg =
            "Please enter a valid email address in Group Leader/Teaching Staff Details.";
          this.showSnackBar = true;
          return;
        }
      }
      if (!/^[a-zA-Z\s]*$/.test(this.instName)) {
        this.msg = "Institution Name contains invalid characters.";
        this.showSnackBar = true;
        return;
      }
      if (!/^[a-zA-Z\s]*$/.test(this.instHeadName)) {
        this.msg =
          "Name of Head of Institution/Organisation contains invalid characters.";
        this.showSnackBar = true;
        return;
      }
      if (!/^[a-zA-Z\s]*$/.test(this.headDesignation)) {
        this.msg =
          "Designation in Institute/Organisation contains invalid characters.";
        this.showSnackBar = true;
        return;
      }
      // for (let i = 0; i < this.leaderDetails.length; i++) {
      //   if (!this.validateEmailleader(this.leaderDetails[i].leaderEmail)) {
      //     this.msg =
      //       "Please enter a valid email address in Group Leader/Teaching Staff Details.";
      //     this.showSnackBar = true;
      //     return;
      //   }
      // }
      if (!/^[a-zA-Z0-9\s,.\-#()]*$/.test(this.instAddress)) {
        this.msg = "Address of the institution contains invalid characters.";
        this.showSnackBar = true;
        return;
      }
      if (!/^[0-9]{11}$/.test(this.instTeleNo)) {
        this.msg = "Institution Phone Number must be 11 digits.";
        this.showSnackBar = true;
        return;
      }
      if (!this.validateEmail()) {
        this.msg = "Please enter a valid institution email address.";
        this.showSnackBar = true;
        return;
      }

      if (!this.isValidAadharNumber()) {
        this.msg = "Aadhaar Number must be exactly 12 digits.";
        this.showSnackBar = true;
        return;
      }
      if (this.idProof && !this.validateFile(this.idProof)) {
        alert(
          "ID Proof of Head file size exceeds 100 KB. Please select a smaller file."
        );
        return;
      }
      if (this.reqLetter && !this.validateFile(this.reqLetter)) {
        alert(
          "Request Letter file size exceeds 100 KB. Please select a smaller file."
        );
        return;
      }
      if (this.participantfile && !this.validateFile(this.participantfile)) {
        alert(
          "Participant file size exceeds 100 KB. Please select a smaller file."
        );
        return;
      }
      if (this.leaderIdProof && !this.validateFile(this.leaderIdProof)) {
        alert(
          "Leader ID Proof 4 file size exceeds 100 KB. Please select a smaller file."
        );
        return;
      }

      const formData = new FormData();
      formData.append("idProof", this.idProof);
      formData.append("reqLetter", this.reqLetter);
      formData.append("participantfile", this.participantfile);
      formData.append("leaderIdProof", this.leaderIdProof);
      formData.append("leaderDetails", JSON.stringify(this.leaderDetails));
      formData.append("memberDetails", JSON.stringify(this.memberDetails));
      formData.append("instHeadName", this.instHeadName);
      formData.append("headDesignation", this.headDesignation);
      formData.append("idCardType", this.idCardType);
      formData.append("idNo", this.idNo);
      formData.append("campid", this.$route.query.id);
      formData.append("instName", this.instName);
      formData.append("instCode", this.instCode);
      formData.append("instType", this.instType);
      formData.append("instDistrict", this.instDistrict);
      formData.append("instAddress", this.instAddress);
      formData.append("instTeleNo", this.instTeleNo);
      formData.append("instMobileNo", this.instMobileNo);
      formData.append("instEmail", this.instEmail);
      formData.append("startDate", this.startDate);
      formData.append("district", this.dist);
      formData.append("campDuration", this.campDuration);
      // formData.append("memberCount", this.totalParticipants);
      // formData.append("maleCount", this.maleCount);
      // formData.append("femaleCount", this.femaleCount);
      // formData.append("transCount", this.transCount);
      formData.append("applicantCategory", this.applicantCategory);
      formData.append("studentCategory", this.studentCategory);
      formData.append("applicantname", this.applicantname);
      axios({
        method: "POST",
        url: "/apply/camp",
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
        data: formData,
      })
        .then((response) => {
          if (response.data.status == true) {
            this.status23 = true;
            this.checkvar = true;
            this.msg = response.data.msg;
            this.appId1 = response.data.data._id;
            this.showSnackBar = true;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.error(err);
        });
    },
    getPreview() {
      const token = localStorage.getItem("token");
      const downloadUrl = "/application/pdf";
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          appid: this.appId1,
        },
        responseType: "blob",
      };

      axios
        .get(downloadUrl, config)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));

          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "preview.pdf");
          document.body.appendChild(link);

          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error("Error downloading invoice:", error);
        });
    },
    // submit() {
    //   axios({
    //     method: "POST",
    //     url: "/final/submit",
    //     headers: {
    //       token: localStorage.getItem("token"),
    //     },
    //     data: {
    //       appid: this.appId1,
    //     },
    //   })
    //     .then((response) => {
    //       if (response.data.status == true) {
    //         this.msg = response.data.msg;
    //         this.showSnackBar = true;
    //         this.submitdialog = false;
    //         alert(response.data.msg);
    //         window.location.reload();
    //         this.$router.push({ path: "/NatureCamps" });
    //       } else {
    //         this.msg = response.data.msg;
    //         this.showSnackBar = true;
    //       }
    //     })
    //     .catch((err) => {
    //       this.ServerError = true;
    //       console.error(err);
    //     });
    // },
    submit() {
      const formData = new FormData();
      formData.append("appid", this.appId1);
      formData.append("idProof", this.idProof);
      formData.append("reqLetter", this.reqLetter);
      formData.append("participantfile", this.participantfile);
      formData.append("leaderIdProof", this.leaderIdProof);
      formData.append("leaderDetails", JSON.stringify(this.leaderDetails));
      formData.append("memberDetails", JSON.stringify(this.memberDetails));
      formData.append("instHeadName", this.instHeadName);
      formData.append("headDesignation", this.headDesignation);
      formData.append("idCardType", this.idCardType);
      formData.append("idNo", this.idNo);
      formData.append("campid", this.$route.query.id);
      formData.append("instName", this.instName);
      formData.append("instCode", this.instCode);
      formData.append("instType", this.instType);
      formData.append("instDistrict", this.instDistrict);
      formData.append("instAddress", this.instAddress);
      formData.append("instTeleNo", this.instTeleNo);
      formData.append("instMobileNo", this.instMobileNo);
      formData.append("instEmail", this.instEmail);
      formData.append("startDate", this.startDate);
      formData.append("district", this.dist);
      formData.append("campDuration", this.campDuration);
      formData.append("applicantCategory", this.applicantCategory);
      formData.append("studentCategory", this.studentCategory);
      formData.append("applicantname", this.applicantname);
      axios({
        method: "POST",
        url: "/final/submit",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.submitdialog = false;
            alert(response.data.msg);
            // window.location.reload();
            this.$router.push({ path: "/NatureCamps" });
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.error(err);
        });
    },
    checkAvailability() {
      axios({
        method: "POST",
        url: "/check/availability",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          startDate: this.startDate,
          campid: this.$route.query.id,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.list = response.data.natureCampDetails;
            this.availableSlots2 = response.data.availableSlots;
            this.message = response.data.data;
            // this.msg = response.data.msg;
            // this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.error(err);
        });
    },
    checkAvailabilityCamp() {
      axios({
        method: "POST",
        url: "/check/availability",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          startDate: this.startDate,
          campid: this.$route.query.id,
          campDuration: this.campDuration,
        },
      })
        .then((response) => {
          if (response.data.status == false) {
            this.list = response.data.natureCampDetails;
            this.availableSlots2 = response.data.availableSlots;
            this.message = response.data.msg;
            this.bookdialog = true;
            // this.msg = response.data.msg;
            // this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.error(err);
        });
    },
    checkAvailability2() {
      axios({
        method: "POST",
        url: "/check/availability",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          memberCount: this.totalParticipants,
          /* maleCount: this.maleCount,
          femaleCount: this.femaleCount,
          transCount: this.transCount,
          availableSlots: this.availableSlots2, */
          campid: this.$route.query.id,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            // this.msg = response.data.msg;
            // this.showSnackBar = true;

            this.list = response.data.natureCampDetails;
          } else {
            this.memberdialog = true;
            this.memberCountMsg = response.data.msg;
            // this.maleCount = 0;
            // this.femaleCount = 0;
            // this.transCount = 0;
            // this.msg = response.data.msg;
            // this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.error(err);
        });
    },
  },
};
</script>
<style>
.no-spinners {
  /* Remove the up and down arrows */
  appearance: textfield;
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
}

.inputPrice input::-webkit-outer-spin-button,
.inputPrice input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>